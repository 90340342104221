<template>
    <div class="container top-space-to-boxes">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="box-full-slider top-boxes">
                    <template>
                        <hooper :progress="true" :items-to-show="1" :auto-play="true" :play-speed="7000">
                            <slide>
                                <b-link to="/" @click="MenuHandler('stockAnalysis')">
                                    <div class="box-picture slider stock-forecast">
                                        <div class="box-picture-text-long">
                                            Stock predictions by analyst
                                        </div>
                                    </div>
                                </b-link>
                            </slide>
                            <slide>
                                <b-link to="/" @click="MenuHandler('youTubers')">
                                    <div class="box-picture slider stock-you-tubers">
                                        <div class="box-picture-text-long">
                                            Top You Tubers that analyze stocks
                                        </div>
                                    </div>
                                </b-link>
                            </slide>
                            <slide>
                                <a target="_blank" href="https://alwy.se/compound-interest">
                                    <div class="box-picture slider nice-to-know">
                                        <div class="box-picture-text-long">
                                            Calculate how big your fortune will be
                                        </div>
                                    </div>
                                </a>
                            </slide>
                            <slide>
                                <a target="_blank" href="https://www.omni.se">
                                    <div class="box-picture slider news">
                                        <div class="box-picture-text-long">
                                            Read the latest news around the world with Omni.
                                        </div>
                                    </div>
                                </a>
                            </slide>
                            <slide>
                                <a target="_blank" href="https://www.idg.se/">
                                    <div class="box-picture slider tech-news">
                                        <div class="box-picture-text-long">
                                            See the latest tech news at IDG
                                        </div>
                                    </div>
                                </a>
                            </slide>
                            <slide>
                                <a target="_self" href="/WeatherForecast">
                                    <div class="box-picture slider temperature">
                                        <div class="box-picture-text-long">
                                            See the latest weather and temperatures
                                        </div>
                                    </div>
                                </a>
                            </slide>
                            <hooper-navigation slot="hooper-addons"></hooper-navigation>
                        </hooper>
                    </template>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12">
                <a target="_self" href="/Lunch">
                    <div class="box-one-third-high-container-lunch">
                        <div class="box-one-third-high lunch">
                            <div class="lunch-inner-box">
                                <div class="lunch-inner-box-header">
                                    <!-- {{ selectedRestaurant.LunchHeader }} -->
                                </div>
                                <table>
                                    <tr>
                                        <!--  <td v-html="selectedRestaurant.FirstDish"></td> -->
                                    </tr>
                                    <tr>
                                        <!-- <td v-html="selectedRestaurant.SecondDish"></td> -->
                                    </tr>
                                    <tr>
                                        <!--  <td v-html="selectedRestaurant.VegitarianDish"></td> -->
                                    </tr>
                                </table>

                                <!-- <div class="lunch-inner-box-link-container">
                                  <div class="lunch-inner-box-link" data-toggle="tooltip" title="Go to next restaurant" v-on:click="getNextRestaurant()>Next restaurant -></div>
                                  <a target="_self" href="/Lunch" class="lunch-inner-box-link" data-toggle="tooltip" title="Go to restaurant page">Restaurant page -></a>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12" v-if="weatherForecastDays.length != 0">
                <b-link title="Weather forecast" to="/WeatherForecast" @click="ScrollToTop()">
                    <div class="box-one-third-high-container-weather">
                        <div class="box-one-third-high weather" :class="weatherForecastDays[0].weatherForecastTimes[1].weatherType">                                                                                                  
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="dayOfWeekAndTime">{{ weatherForecastDays[0].dayOfWeek}}  {{ weatherForecastDays[0].weatherForecastTimes[1].time}}:00</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="temperature">{{ weatherForecastDays[0].weatherForecastTimes[1].temperature}} °C</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="region">Malmö</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="properties">
                                        <span><img src="../assets/images/weather/icon-rain.png" />  {{ weatherForecastDays[0].weatherForecastTimes[1].precipitation}} mm </span>
                                        <span class="ml-5"></span><img src="../assets/images/weather/icon-wind.png" />  {{ weatherForecastDays[0].weatherForecastTimes[1].windSpeed}} m/s
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="properties2">
                                        <span></span><img src="../assets/images/weather/icon-air-pressure.png" />  {{ weatherForecastDays[0].weatherForecastTimes[1].airPressure}} hPa
                                        <span class="ml-5"></span><img src="../assets/images/weather/icon-humidity.png" />  {{ weatherForecastDays[0].weatherForecastTimes[1].relativeHumidity}} %
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-link>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12" v-if="weatherForecastDays.length == 0">  
                <div class="box-one-third-high-container-weather">
                    <div class="box-one-third-high weather clear-sky">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="loader"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <a target="_blank" href="https://www.idg.se/">
                    <div class="box-one-third-container-high">
                        <div class="box-one-third-high">
                            <div class="box-picture news">
                                <div class="box-picture-text">IDG</div>
                            </div>
                            <div class="box-one-third-text-wrapper">
                                <div class="box-one-third-header">
                                    Latest tech news
                                </div>
                                <p>Read the latest tech and development news on the market at IDG.</p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>     
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12" id="lucid">
                <a target="_blank" alt="Lucid Air Sapphire" href="https://www.lucidmotors.com/stories/introducing-sapphire-pinnacle-electric-performance">
                    <div class="box-full lucid-air-sapphire">
                        <div class="box-picture-text">
                            Lucid Air Sapphire
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12" id="stockAnalysis">
                <div class="box-one-third-container-links">
                    <div class="box-one-third-links">
                        <div class="box-picture stock-chart"></div>
                        <div class="box-one-third-text-wrapper">
                            <div class="box-one-third-header">
                                Stock analysis
                            </div>
                            <ul>
                                <li class="mt-5"><a target="_blank" href="https://www.tipranks.com/">Tipranks</a></li>
                                <li><a target="_blank" href="https://www.borskollen.se/">Börskollen</a></li>
                                <li><a target="_blank" href="https://finviz.com/">Finviz</a></li>
                                <li><a target="_blank" href="https://www.marketbeat.com/">Marketbeat</a></li>
                                <li><a target="_blank" href="https://borsdata.se/">Börsdata</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12" id="youTubers">
                <div class="box-one-third-container-links">
                    <div class="box-one-third-links">
                        <div class="box-picture stock-you-tubers"></div>
                        <div class="box-one-third-text-wrapper">
                            <div class="box-one-third-header">
                                YouTubers
                            </div>
                            <ul>
                                <li class="mt-5"><a target="_blank" href="https://www.youtube.com/c/StockMartin">Stock Martin </a></li>
                                <li><a target="_blank" href="https://www.youtube.com/c/BearsWorkshop">Bears workshop</a></li>
                                <li><a target="_blank" href="https://www.youtube.com/channel/UCYXXqx_x6fD_l7Yi0qSIBOw">Visceral Ace </a></li>
                                <li><a target="_blank" href="https://www.youtube.com/c/TalkinInvesting">Talkin' Investing </a></li>
                                <li><a target="_blank" href="https://www.youtube.com/c/FREENVESTING">Free Investing </a></li>
                                <li><a target="_blank" href="https://www.youtube.com/results?search_query=mad+money+jim+cramer">Mad Money</a></li>
                                <li><a target="_blank" href="https://www.youtube.com/@FinancialJourney">Financial Journey</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12" id="goodToKnow">
                <div class="box-one-third-container-links">
                    <div class="box-one-third-links">
                        <div class="box-picture nice-to-know"></div>
                        <div class="box-one-third-text-wrapper">
                            <div class="box-one-third-header">
                                Good to know
                            </div>
                            <ul>
                                <li class="mt-5"><b-link to="/FortuneCalculator" @click="ScrollToTop()">Fortune calculator</b-link></li>
                                <li><a target="_blank" href="https://rikatillsammans.se/verktyg/kalkylator-kapital-som-behovs-for-din-ekonomiska-frihet/">Rika tillsammans</a></li>
                                <li><a target="_blank" href="https://www.nordnet.se/blogg/garanterad-avkastning-2/">Garanterad avkastning</a></li>
                                <li><a target="_blank" href="https://www.investopedia.com/world-s-11-greatest-investors-4773356">Best investors in the world</a></li>
                                <li><a target="_blank" href="https://www.aktiespararna.se/nyheter">aktiespararna</a></li>
                                <li><a target="_blank" href="https://chat.openai.com/auth/login">ChatGPT</a></li>
                                <li><a target="_blank" href="https://ibindex.se/ibi/#/index">Investmentbolags-index</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <a target="_blank" href="https://www.youtube.com/c/BearsWorkshop/videos">
                    <div class="box-full bears-workshop"></div>
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12" id="stockBrokers">
                <div class="box-one-third-container-links">
                    <div class="box-one-third-links">
                        <div class="box-picture wind-energy"></div>
                        <div class="box-one-third-text-wrapper">
                            <div class="box-one-third-header">
                                Wind energy
                            </div>
                            <ul>
                                <li class="mt-5"><a target="_blank" href="https://www.youtube.com/watch?v=xy9nj94xvKA">How do wind turbines work?</a></li>
                                <li><a target="_blank" href="https://www.youtube.com/watch?v=7w6Qj0iDVHE">All About Wind Energy</a></li>
                                <li><a target="_blank" href="https://www.youtube.com/watch?v=f0p0Fria5TY">Future of Renewable Energy</a></li>
                                <li><a target="_blank" href="https://www.youtube.com/watch?v=WsswrLKlinU">The truth about wind turbines</a></li>
                                <li><a target="_blank" href="https://www.youtube.com/watch?v=h4Oe1ifauPU">Harnessing the power of wind energy</a></li>
                                <li><a target="_blank" href="https://www.youtube.com/watch?v=xhED_ZxC9NQ">New Energy Breakthrough</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12" id="hydrogen">
                <div class="box-one-third-container-links">
                    <div class="box-one-third-links">
                        <div class="box-picture hydrogen-molecule"></div>
                        <div class="box-one-third-text-wrapper">
                            <div class="box-one-third-header">
                                Hydrogen
                            </div>
                            <ul>
                                <li class="mt-5"><a target="_blank" href="https://www.youtube.com/watch?v=AGTjKJHu99c&t=15s">The truth about hydrogen</a></li>
                                <li><a target="_blank" href="https://www.youtube.com/watch?v=fkX-H24Chfw">Hydrogen: fuel of the future?</a></li>
                                <li><a target="_blank" href="https://www.youtube.com/watch?v=4sn0ecqZgog">The answer to the climate crisis?</a></li>
                                <li><a target="_blank" href="https://www.youtube.com/watch?v=JGe8R0N20ps">Hydrogen, the Next Clean Fuel</a></li>
                                <li><a target="_blank" href="https://www.youtube.com/watch?v=rc5ETK9aUk4">Hydrogen Storage Breakthrough</a></li>
                                <li><a target="_blank" href="https://www.youtube.com/watch?v=7bHGg2EpbHI">Swedish Stirling's technology</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12" id="solarEnergy">
                <div class="box-one-third-container-links">
                    <div class="box-one-third-links">
                        <div class="box-picture solar-energy"></div>
                        <div class="box-one-third-text-wrapper">
                            <div class="box-one-third-header">
                                Solar energy
                            </div>
                            <ul>
                                <li class="mt-5"><a target="_blank" href="https://www.youtube.com/watch?v=sUvaYycoWqI">How solar energy got so cheap?</a></li>
                                <li><a target="_blank" href="https://www.youtube.com/watch?v=oiPSy2bKZkE">Solar Plant was an EPIC Failure</a></li>
                                <li><a target="_blank" href="https://www.youtube.com/watch?v=ZvdENQ5KMjo">How mirrors could power the planet</a></li>
                                <li><a target="_blank" href="https://www.youtube.com/watch?v=3JqzSsStwF4">The Truth About Solar Panels</a></li>
                                <li><a target="_blank" href="https://www.youtube.com/watch?v=xKxrkht7CpY">How do solar panels work?</a></li>
                                <li><a target="_blank" href="https://www.youtube.com/watch?v=b1LQSezKxnA">China Plans to Win Future of Energy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12" id="stockBrokers">
                <div class="box-one-third-container-links">
                    <div class="box-one-third-links">
                        <div class="box-picture stock-brokers"></div>
                        <div class="box-one-third-text-wrapper">
                            <div class="box-one-third-header">
                                Stock and fond brokers
                            </div>
                            <ul>
                                <li class="mt-5"><a target="_blank" href="https://www.plus500.com/sv/">Plus 500</a></li>
                                <li><a target="_blank" href="https://www.avanza.se/">Avanza</a></li>
                                <li><a target="_blank" href="https://www.nordnet.se/se">Nordnet</a></li>
                                <li><a target="_blank" href="https://www.cmcmarkets.com/sv-se/">CMC Markets</a></li>
                                <li><a target="_blank" href="https://www.etoro.com/">Etoro</a></li>
                                <li><a target="_blank" href="https://www.ig.com/se">IG</a></li>
                                <li><a target="_blank" href="https://savr.com/sv">Savr</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12" id="hydrogen">
                <div class="box-one-third-container-links">
                    <div class="box-one-third-links">
                        <div class="box-picture biggest-companies"></div>
                        <div class="box-one-third-text-wrapper">
                            <div class="box-one-third-header">
                                Largest companies
                            </div>
                            <ul>
                                <li class="mt-5"><a target="_blank" href="https://companiesmarketcap.com/software/largest-software-companies-by-market-cap/">Tech companies</a></li>
                                <li><a target="_blank" href="https://companiesmarketcap.com/automakers/largest-automakers-by-market-cap/">Automakers</a></li>
                                <li><a target="_blank" href="https://companiesmarketcap.com/mining/largest-mining-companies-by-market-cap/">Mining</a></li>
                                <li><a target="_blank" href="https://companiesmarketcap.com/healthcare/largest-healthcare-companies-by-market-cap/">Healthcare</a></li>
                                <li><a target="_blank" href="https://companiesmarketcap.com/pharmaceuticals/largest-pharmaceutical-companies-by-market-cap/">Pharmaceuticals</a></li>
                                <li><a target="_blank" href="https://companiesmarketcap.com/oil-gas/largest-oil-and-gas-companies-by-market-cap/">Oil and gas</a></li>
                                <li><a target="_blank" href="https://companiesmarketcap.com/banks/largest-banks-by-market-cap/">Banks</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12" id="solarEnergy">
                <div class="box-one-third-container-links">
                    <div class="box-one-third-links">
                        <div class="box-picture blogs"></div>
                        <div class="box-one-third-text-wrapper">
                            <div class="box-one-third-header">
                                Market blogs
                            </div>
                            <ul>
                                <li class="mt-5"><a target="_blank" href="https://cornucopia.se/">cornucopia.se</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12">
                <a target="_self" href="/Lunch">
                    <div class="box-one-third-high-container-lunch">
                        <div class="box-one-third-high lunch">
                            <div class="lunch-inner-box">
                                <div class="lunch-inner-box-header">-->
                                    <!-- {{ selectedRestaurant.LunchHeader }} -->
                                <!--</div>
                                <table>
                                    <tr>-->
                                          <!--  <td v-html="selectedRestaurant.FirstDish"></td> -->
                                    <!--</tr>
                                    <tr>-->
                                        <!-- <td v-html="selectedRestaurant.SecondDish"></td> -->
                                    <!--</tr>
                                    <tr>-->
                                        <!--  <td v-html="selectedRestaurant.VegitarianDish"></td> -->
                                    <!--</tr>
                                </table>-->

                                <!-- <div class="lunch-inner-box-link-container">
                                  <div class="lunch-inner-box-link" data-toggle="tooltip" title="Go to next restaurant" v-on:click="getNextRestaurant()>Next restaurant -></div>
                                  <a target="_self" href="/Lunch" class="lunch-inner-box-link" data-toggle="tooltip" title="Go to restaurant page">Restaurant page -></a>
                                </div> -->
                            <!--</div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <a target="_blank" href="https://omni.se/">
                    <div class="box-one-third-container-high">
                        <div class="box-one-third-high">
                            <div class="box-picture articles">
                                <div class="box-picture-text">Omni</div>
                            </div>
                            <div class="box-one-third-text-wrapper">
                                <div class="box-one-third-header">Latest news in the world</div>
                                <p>
                                    Read the latest news in short format at Omni. Best of all, its free.
                                </p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <a target="_blank" href="https://www.idg.se/">
                    <div class="box-one-third-container-high">
                        <div class="box-one-third-high">
                            <div class="box-picture news">
                                <div class="box-picture-text">IDG</div>
                            </div>
                            <div class="box-one-third-text-wrapper">
                                <div class="box-one-third-header">
                                    Latest tech news
                                </div>
                                <p>Read the latest tech and development news on the market at IDG.</p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>-->
    </div>
</template>

<script>
    import "../../src/styles/_start-page.scss";
    import "../../src/styles/_layout.scss";
    import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
    import "hooper/dist/hooper.css";

    const axios = require("axios");

    export default {
        name: "Start",
        components: {
            Hooper,
            Slide,
            HooperNavigation
        },
        baseUrl: process.env.VUE_APP_WEB_API,

        data: function () {
            return {
                ReportModel: {
                    ResultModel: {
                        Code: "",
                        Success: false,
                        ShowAlert: false,
                        Message: "",
                    },
                },
                id: this.$route.params.id,
                showSpinner: true,
                showErrorCode: false,
                MyEnvironmentString: process.env.VUE_APP_TITLE,
                baseUrl: process.env.VUE_APP_WEB_API,
                weatherForecastDays: [],            
            };
        },

        methods: {
            MenuHandler: function (linkId) {
                document.getElementById(linkId).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            },

            ScrollToTop: function () {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
            },

            getWeatherForecast: function () {
                var self = this;
                self.showSpinner = true;
                axios
                    .get(self.baseUrl + "api/WeatherForecast/GetWeather")
                    .then(function (response) {
                        if (response.data.resultDTO.success == true) {
                            self.weatherForecastDays = response.data.weatherForecastDays;
                        }
                        else {
                            console.log(response.data.resultDTO.message);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.error = true;                     
                    });
                self.showSpinner = false;
            }
        },

        /*  getOneDayLunch: function () {
                var self = this;
                axios.get('/LunchPage/GetOneDayLunch')
                    .then(function (response) {
                        console.log(response.data);
                        console.log(response.status);
                        self.twoValveAndKitchenOneDayHtmlTable = response.data.TwoValveAndKitchenOneDayHtmlTable;
                    })
                    .catch(error => {
                        console.log(error);
                        this.error = true;
                        alert("error");
                    });
            } */


        mounted() {
            this.getWeatherForecast();
        },
    };
</script>
